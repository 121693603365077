import React from 'react'
import data from "./gbrSertifikat";
const Sertifikat = (props) => {
    const itemSlice = data.slice(0 , props.item)
    return (
        <div className="row">
            {itemSlice.map((value , i ) => (
                <div className={`${props.column}`} key={i}>
                    <div className='team-static'>
                        <div className="thumbnail">
                            <img src={`/assets/images/sertifikat/legalitas-${value.images}.jpeg`} alt="Blog Images"/>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default Sertifikat