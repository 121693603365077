
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import I18NextHttpBackend from 'i18next-http-backend';




i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({  
    // fallbackLng: 'eng',
    detection:{
      order: ['localStorage','htmlTag', 'sessionStorage', 'navigator', 'path' , 'subdomain'],
      caches: ['localStorage']
    },backend: {
      loadPath: 'assets/locales/{{lng}}/translation.json',
    },
    react:{
      useSuspense: false
    }
  });

  export default i18n;
