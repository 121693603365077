import React from 'react'
import { Translation } from 'react-i18next';

const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            {/* <span>READY TO DO THIS</span> */}
                            <Translation>{t => <h3 style={{color:'white'}}>{t('corporate_bussiness.callAction1')}</h3>}</Translation>
                            <Translation>{t => <a className="rn-button-style--2" href="/contact"><span>{t('corporate_bussiness.buttonCallAction')}</span></a>}</Translation>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;