import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import TabTwo from '../elements/tab/TabTwo';
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import Chart from './Grafik';
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/header/Header";
import Header from "../component/header/HeaderFive";
import Sertifikat from "../blocks/team/Sertifikat";
import Footer from "../component/footer/Footer";
import { Translation } from 'react-i18next';


class About extends Component{
    render(){
        let title = "about.ttg_kami",
        
        description = "about.deskripsi_tk";
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* Start Breadcrump Area */}

                
                <Translation>{t => <Breadcrumb title={t(`about.ttg_kami`)}></Breadcrumb>}</Translation>
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/kantor-megah.jpeg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            
                                            <Translation>{t => <h2 className="title">{t(`about.ttg_kami`)}</h2>}</Translation>
                                            <Translation>{t => <p className="description" style={{textAlign:'justify'}}>{t(`about.deskripsi_tk`)}</p>}</Translation>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    {/* <h3 className="title">Visi</h3> */}
                                                    <Translation>{t => <h3 className="title">{t(`about.visi`)}</h3>}</Translation>
                                                    {/* <p style={{textAlign:'justify'}}>Menjadi perusahaan penyedia jasa tenaga kerja terbaik di berbagai bidang yang dapat dipercaya dan menjadi pilihan utama bagi masyarakat dan korporasi.</p> */}
                                                    <Translation>{t => <p style={{textAlign:'justify'}}>{t(`about.detail_visi`)}</p>}</Translation>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list" style={{textAlign:'justify'}}>
                                                    <Translation>{t => <h3 className="title">{t(`about.misi`)}</h3>}</Translation>
                                                    <ul>
                                                        
                                                        <Translation>{t => <li>{t(`about.detail_misi1`)}</li>}</Translation>
                                                        
                                                        <Translation>{t => <li>{t(`about.detail_misi2`)}</li>}</Translation>
                                                        
                                                        <Translation>{t => <li>{t(`about.detail_misi3`)}</li>}</Translation>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    
                    <div className="container">
                    <div className="section-title">
                        <Translation>{t => <h2 className="title">{t(`about.nilai_perusahaan`)}</h2>}</Translation>
                    </div>
                    <TabTwo tabStyle="tab-style--1" />
                    </div>
                </div>


                <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <Translation>{t => <h2 className="title">{t(`about.perkembangan_perusahaan`)}</h2>}</Translation>
                                        </div>
                                        <div className="row mt--30">
                                        <Chart/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <Translation>{t => <h2 className="title">{t(`about.legalitas`)}</h2>}</Translation>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Sertifikat column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="5" />
                        </div>
                    </div>
                </div>

                {/* Start Team Area  */}
                {/* <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Skilled Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row"> */}
                            
                            {/* Start Single Team  */}
                            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-01.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-02.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">BM. Alamin</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-03.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Jone Due</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* End Single Team  */}

                        {/* </div>
                    </div>
                </div> */}
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About