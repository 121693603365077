import React, { Component } from "react";

class Brand extends Component {
  render() {
    const { branstyle } = this.props;
    return (
      <React.Fragment>
        <ul className={`brand-list ${branstyle}`}>
          {Array.from(Array(38), (e, i) => {
            return (
              <li>
                <img
                  src={`/assets/images/brand/client/Client${i + 1}.png`}
                  alt="Logo Images"
                />
              </li>
            );
          })}

          {/* <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                    </li> */}
        </ul>
      </React.Fragment>
    );
  }
}
export default Brand;
