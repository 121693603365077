import React, { Component }  from 'react';
import { Translation } from 'react-i18next';

const BlogContent = [
    {
        images: 'Galeri 1',
        title: <Translation>{t => <p>{t('corporate_bussiness.beladiri')}</p>}</Translation>,
        category: <Translation>{t => <p>{t('corporate_bussiness.pengembangan_diri')}</p>}</Translation>
    },
    {
        images: 'Galeri 2',
        title: <Translation>{t => <p>{t('corporate_bussiness.kebakaran')}</p>}</Translation>,
        category: <Translation>{t => <p>{t('corporate_bussiness.pengembangan_diri')}</p>}</Translation>
    },
    {
        images: 'Galeri 3',
        title: <Translation>{t => <p>{t('corporate_bussiness.kebakaran')}</p>}</Translation>,
        category: <Translation>{t => <p>{t('corporate_bussiness.pengembangan_diri')}</p>}</Translation>
    },
    {
        images: 'Galeri 4',
        title: <Translation>{t => <p>{t('corporate_bussiness.bencana_alam')}</p>}</Translation>,
        category: <Translation>{t => <p>{t('corporate_bussiness.pengembangan_diri')}</p>}</Translation>
    },
    {
        images: 'Galeri 5',
        title: <Translation>{t => <p>{t('corporate_bussiness.bencana_alam')}</p>}</Translation>,
        category: <Translation>{t => <p>{t('corporate_bussiness.pengembangan_diri')}</p>}</Translation>
    },
    {
        images: 'Galeri 6',
        title: <Translation>{t => <p>{t('corporate_bussiness.pengembangan_diri')}</p>}</Translation>,
        category: <Translation>{t => <p>{t('corporate_bussiness.kedisiplinan')}</p>}</Translation>
    },
]

export default BlogContent;