import React, { Component, Fragment } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Accordion01 from "../elements/Accordion";
import Helmet from "../component/common/Helmet";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiChevronUp,
  FiCheck,
} from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import { portfolioSlick2 } from "../page-demo/script";
import { useTranslation, Trans } from "react-i18next";
import { Translation } from "react-i18next";

import "../translations/i18n";
import BlogGalery from "../elements/blog/GaleryContent";

// const languages = [
//     {
//         code : 'en',
//         name: 'English',
//         country_code: 'en'
//     },
//     {
//         code : 'in',
//         name: 'Indonesia',
//         country_code: 'in'
//     }
// ]

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--33",
    category: "",
    title: "corporate_bussiness.title_slide1",
    description: "corporate_bussiness.deskripsi_slide1",
    buttonText: "corporate_bussiness.button_slide1",
    buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--34",
    category: "",
    title: "corporate_bussiness.title_slide2",
    description: "corporate_bussiness.deskripsi_slide2",
    buttonText: "corporate_bussiness.button_slide2",
    buttonLink: "/service",
  },
];
console.log(SlideList[0]);
const ServiceListOne = [
  {
    icon: <FiCast />,
    title: "Business Stratagy",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiUsers />,
    title: "Marketing & Reporting",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
];

const starndardService = [
  {
    image: "guard_1",
    title: "corporate_bussiness.jasa_keamanan",
    description: "corporate_bussiness.detail_jasa_keamanan",
    link: "/service-details",
    target: "",
  },
  // {
  //   image: "training_1",
  //   title: "corporate_bussiness.jasa_pelatihan",
  //   description: "corporate_bussiness.detail_jasa_pelatihan",
  //   link: "/service-details",
  //   target: "",
  // },
  {
    image: "it_1",
    title: "corporate_bussiness.it",
    description: "corporate_bussiness.detail_it",
    link: "https://it.bapguard.com/",
    target: "_blank",
  },
  {
    image: "clean_1",
    title: "corporate_bussiness.jasa_kebersihan",
    description: "corporate_bussiness.detail_jasa_kebersihan",
    link: "/service-details",
    target: "",
  },
  // {
  //   image: "drive_1",
  //   title: "corporate_bussiness.jasa_transportasi",
  //   description: "corporate_bussiness.detail_jasa_transportasi",
  //   link: "/service-details",
  //   target: "",
  // },
  {
    image: "training_1",
    title: "corporate_bussiness.jasa_marketing",
    description: "corporate_bussiness.detail_jasa_marketing",
    link: "/service-details",
    target: "",
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    var namesItemOne = [
      "The Philosophy Of business analytics",
      "Fast-Track Your business",
      "Lies And Damn Lies About business",
      "The Ultimate Deal On business",
    ];
    var namesItemTwo = [
      "Proof That business Really Works",
      "Here Is What You Should Do For Your business",
      "The Hidden Mystery Behind business",
    ];

    const PostList = BlogContent;
    const PostGalery = BlogGalery;

    return (
      <Fragment>
        <Helmet pageTitle="Corporate Business" />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <Translation>
                              {(t) => (
                                <h1 className="title">{t(`${value.title}`)}</h1>
                              )}
                            </Translation>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <Translation>
                              {(t) => (
                                <p className="description">
                                  {t(`${value.description}`)}
                                </p>
                              )}
                            </Translation>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <Translation>
                              {(t) => (
                                <div className="slide-btn">
                                  <a
                                    className="rn-button-style--2 btn-solid"
                                    href={`${value.buttonLink}`}
                                  >
                                    {t(`${value.buttonText}`)}
                                  </a>
                                </div>
                              )}
                            </Translation>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        {/* <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start About Area  */}
        <div
          className="rn-about-area bg_color--5"
          style={{ paddingTop: 120, paddingBottom: 50 }}
        >
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/fotopakramu.jpeg"
                    alt="About Images"
                  />
                  <p>
                    <i>
                      Ramu Surachman, <br />
                      <Translation>
                        {(t) => <p>{t("corporate_bussiness.dirut")}</p>}
                      </Translation>
                    </i>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">
                      <Translation>
                        {(t) => (
                          <h2>{t("corporate_bussiness.tentang_kami")}</h2>
                        )}
                      </Translation>
                    </h2>
                    <p style={{ textAlign: "justify" }}>
                      <Translation>
                        {(t) => <p>{t("corporate_bussiness.deskripsi")}</p>}
                      </Translation>
                    </p>
                  </div>
                  {/* <div className="accordion-wrapper mt--30">
                                        <Accordion01 />
                                    </div> */}
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      <Translation>
                        {(t) => <p>{t("corporate_bussiness.tombol_detail")}</p>}
                      </Translation>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="rn-about-area bg_color--5"
          style={{ paddingBottom: 120 }}
        >
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <video
                    src="/assets/video.mp4"
                    width="100%"
                    height="70%"
                    controls
                    autoplay
                    loop
                  ></video>
                  {/* <img
                    className="w-100"
                    src="/assets/images/about/fotopakramu.jpeg"
                    alt="About Images"
                  /> */}
                  {/* <p>
                    <i>
                      Ramu Surachman, <br />
                      <Translation>
                        {(t) => <p>{t("corporate_bussiness.dirut")}</p>}
                      </Translation>
                    </i>
                  </p> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Integrated Facility Management</h2>
                    <p style={{ textAlign: "justify" }}>
                      <Translation>
                        {(t) => <p>{t("corporate_bussiness.deskripsi_ifm")}</p>}
                      </Translation>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Counterup Area */}
        <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="7"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div>
        {/* End Counterup Area */}
        {/* Start Featured Service Area  */}
        <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="col-lg-3 col-md-6 col-12 mt--30">
                <div className="section-title">
                  <h2 className="title">
                    <Translation>
                      {(t) => <p>{t("corporate_bussiness.layanan_kami")}</p>}
                    </Translation>
                  </h2>
                  <p>
                    <Translation>
                      {(t) => <p>{t("corporate_bussiness.sedia_layanan")}</p>}
                    </Translation>
                  </p>
                  <div className="service-btn">
                    <a className="btn-transparent rn-btn-dark" href="/contact">
                      <span className="text">
                        <Translation>
                          {(t) => (
                            <p>{t("corporate_bussiness.layanan_lain")}</p>
                          )}
                        </Translation>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {/* End Single Service  */}

              {/* Start Single Service  */}
              <div className="col-lg-9">
                <div className="row">
                  {starndardService.map((value, index) => (
                    <div
                      className="col-lg-5 mr-auto col-md-5 mt--30"
                      key={index}
                    >
                      <div className="standard-service">
                        <div className="thumbnai">
                          <img
                            src={`/assets/images/service/${value.image}.png`}
                            alt="Corporate Images"
                          />
                        </div>
                        <div className="content">
                          {/* <h3><a href="/service-details">{value.title}</a></h3> */}
                          <Translation>
                            {(t) => <h3>{t(`${value.title}`)}</h3>}
                          </Translation>
                          <Translation>
                            {(t) => <p>{t(`${value.description}`)}</p>}
                          </Translation>
                          {/* <a className="btn-transparent rn-btn-dark" href={value.link} target={value.target}><span className="text">Baca Selengkapnya</span></a> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Team Area  */}
        {/* <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Managing Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="4" />
                        </div>
                    </div>
                </div> */}
        {/* End Team Area  */}

        {/* Start About Area  */}
        {/* <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable.</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Lorem ipsum dolor sit.</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Lorem ipsum dolor sit.</h4>
                                        <ul className="list-style--1">
                                            {namesItemTwo.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/assets/images/about/about-3.png" alt="About Images"/>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End About Area  */}

        {/* Start Brand Area  */}
        {/* <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Brand Area  */}

        {/* Start Pricing Tbale Area  */}
        {/* <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Pricing Plan</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row"> */}
        {/* Start PRicing Table Area  */}
        {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Free</h4>
                                            <div className="pricing">
                                                <span className="price">29</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
        {/* End PRicing Table Area  */}

        {/* Start PRicing Table Area  */}
        {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Business</h4>
                                            <div className="pricing">
                                                <span className="price">29</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
        {/* End PRicing Table Area  */}

        {/* Start PRicing Table Area  */}
        {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Advanced</h4>
                                            <div className="pricing">
                                                <span className="price">29</span>
                                                <span className="subtitle">USD Per Month</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 PPC Campaigns</li>
                                                <li><FiCheck /> Digital Marketing</li>
                                                <li><FiCheck /> Marketing Agency</li>
                                                <li><FiCheck /> Seo Friendly</li>
                                                <li><FiCheck /> UI/UX designs</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#pricing">Purchase Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
        {/* End PRicing Table Area  */}
        {/* </div>
                    </div>
                </div> */}
        {/* End Pricing Tbale Area  */}

        {/* Start Blog Area */}
        <div
          className="rn-blog-area pb--80 bg_color--1"
          style={{ paddingTop: 120 }}
        >
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h2 className="title">
                    <Translation>
                      {(t) => (
                        <p>{t("corporate_bussiness.galeri_kerjasama")}</p>
                      )}
                    </Translation>
                  </h2>
                  <p>
                    <Translation>
                      {(t) => (
                        <p>{t("corporate_bussiness.dokumentasi_kerjasama")}</p>
                      )}
                    </Translation>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostGalery.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      {/* <a onClick={e => e.preventDefault()} href="/blog-details"> */}
                      <img
                        className="w-100"
                        src={`/assets/images/kerjasama/${value.images}`}
                        alt="Blog Images"
                      />
                      {/* </a> */}
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4
                        className="title"
                        style={{ color: "white", marginBottom: "60px" }}
                      >
                        {value.title}
                      </h4>

                      {/* <h4 className="title"><a href="/blog-details">{value.title}</a></h4> */}
                      {/* <div className="blog-btn">
                                                <a onClick={e => e.preventDefault()} className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rn-blog-area pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <h2 className="title">
                    <Translation>
                      {(t) => (
                        <p>{t("corporate_bussiness.dokumentasi_pelatihan")}</p>
                      )}
                    </Translation>
                  </h2>
                  <p>
                    <Translation>
                      {(t) => (
                        <p>
                          {t(
                            "corporate_bussiness.detail_dokumentasi_pelatihan"
                          )}
                        </p>
                      )}
                    </Translation>
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      {/* <a onClick={e => e.preventDefault()} href="/blog-details"> */}
                      <img
                        className="w-100"
                        src={`/assets/images/kegiatan/${value.images}.jpg`}
                        alt="Blog Images"
                      />
                      {/* </a> */}
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4
                        className="title"
                        style={{ color: "white", marginBottom: "60px" }}
                      >
                        {value.title}
                      </h4>

                      {/* <h4 className="title"><a href="/blog-details">{value.title}</a></h4> */}
                      {/* <div className="blog-btn">
                                                <a onClick={e => e.preventDefault()} className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* End Blog Area */}

        {/* Start call To Action  */}
        <CallAction />
        {/* End call To Action  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
