import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Translation } from 'react-i18next';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = <Translation>{t => <span>{t(`about.integritas`)}</span>}</Translation>,
        tab2 = <Translation>{t => <span>{t(`about.profesional`)}</span>}</Translation>,
        tab3 =  <Translation>{t => <span>{t(`about.tanggungjawab`)}</span>}</Translation>,
        tab4 =  <Translation>{t => <span>{t(`about.inovasi`)}</span>}</Translation>,
        tab5 =  <Translation>{t => <span>{t(`about.kerjasama_tim`)}</span>}</Translation>,
        tab6 =  <Translation>{t => <span>{t(`about.kepuasan_klien`)}</span>}</Translation>;
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                        <Tab>{tab5}</Tab>
                                        <Tab>{tab6}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                           <Translation>{t => <p>{t(`about.detail_integritas`)}</p>}</Translation>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <Translation>{t => <p>{t(`about.detail_profesional`)}</p>}</Translation>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                          <Translation>{t => <p>{t(`about.detail_tanggungjawab`)}</p>}</Translation>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <Translation>{t => <p>{t(`about.detail_inovasi`)}</p>}</Translation>
                                       </div>
                                    </TabPanel>
                                    
                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <Translation>{t => <p>{t(`about.detail_kerjasama_tim`)}</p>}</Translation>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <Translation>{t => <p>{t(`about.detail_kepuasan_klien`)}</p>}</Translation>
                                       </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;