import React, { useRef, useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

function Grafik(props) {
  useLayoutEffect(() => {
    var chart = am4core.create("chartdiv", am4charts.XYChart3D);
    // Add data
    chart.data = [
      {
        tahun: "2005",
        karyawan: 742,
      },
      {
        tahun: "2006",
        karyawan: 786,
      },
      {
        tahun: "2007",
        karyawan: 1057,
      },
      {
        tahun: "2008",
        karyawan: 1205,
      },
      {
        tahun: "2009",
        karyawan: 1366,
      },
      {
        tahun: "2010",
        karyawan: 1898,
      },
      {
        tahun: "2011",
        karyawan: 2058,
      },
      {
        tahun: "2012",
        karyawan: 2305,
      },
      {
        tahun: "2013",
        karyawan: 2271,
      },
      {
        tahun: "2014",
        karyawan: 2822,
      },
      {
        tahun: "2015",
        karyawan: 3085,
      },
      {
        tahun: "2016",
        karyawan: 3517,
      },
      {
        tahun: "2017",
        karyawan: 3659,
      },
      {
        tahun: "2018",
        karyawan: 3293,
      },
      {
        tahun: "2019",
        karyawan: 3533,
      },
      {
        tahun: "2020",
        karyawan: 2826,
      },
      {
        tahun: "2021",
        karyawan: 2685,
      },
      {
        tahun: "2022",
        karyawan: 2771,
      },
      {
        tahun: "2023",
        karyawan: 3012,
      },
    ];

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "tahun";
    categoryAxis.renderer.labels.template.rotation = 295;
    categoryAxis.renderer.labels.template.hideOversized = false;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.horizontalCenter = "middle";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.tooltip.label.rotation = 270;
    categoryAxis.tooltip.label.horizontalCenter = "right";
    categoryAxis.tooltip.label.verticalCenter = "middle";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Karyawan";
    valueAxis.title.fontWeight = "bold";

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "karyawan";
    series.dataFields.categoryX = "tahun";
    series.name = "Grafik";
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#FFFFFF");

    columnTemplate.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    columnTemplate.adapter.add("stroke", function (stroke, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.strokeOpacity = 0;
    chart.cursor.lineY.strokeOpacity = 0;
    chart.appear();
    chart.series.each(function (series) {
      series.appear();
    });
  }, []);

  return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
}
export default Grafik;
