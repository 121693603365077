import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>PT. Bina Area Persada</title>
                    <meta name="description" content="1991 was the starting point of PT. BAP. Starting from the management of security services and building maintenance services in the city of Surabaya, which then grew rapidly, and in 2004 PT. BAP has officially become a very successful Security Service company, due to the support and trust from our business partners from the fields of Industry, Warehousing, Banking, Education, Automotive and Hospitals." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
