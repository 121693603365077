import React, { Component } from "react";
import { Translation } from "react-i18next";

const BlogGalery = [
  {
    images: "Galeri 1.png",
    title: (
      <Translation>{(t) => <p>{t("corporate_bussiness.team")}</p>}</Translation>
    ),
    category: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kerjasama")}</p>}
      </Translation>
    ),
  },
  {
    images: "Galeri 2.png",
    title: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kerjasama")}</p>}
      </Translation>
    ),
    category: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kerjasama")}</p>}
      </Translation>
    ),
  },
  {
    images: "Galeri 3.jpeg",
    title: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kegiatan_bootcamp")}</p>}
      </Translation>
    ),
    category: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kegiatan")}</p>}
      </Translation>
    ),
  },
  {
    images: "Galeri 4.jpeg",
    title: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kegiatan_bootcamp")}</p>}
      </Translation>
    ),
    category: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kegiatan")}</p>}
      </Translation>
    ),
  },
  {
    images: "Galeri 5.jpeg",
    title: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.presentasi")}</p>}
      </Translation>
    ),
    category: (
      <Translation>
        {(t) => <p>{t("corporate_bussiness.kegiatan")}</p>}
      </Translation>
    ),
  },
];

export default BlogGalery;
