import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import BlogContent from "../elements/blog/BlogContent";
import { Translation } from "react-i18next";
import BlogGalery from "./blog/GaleryContent";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    const PostList = BlogContent;
    const PostGalery = BlogGalery;
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contact" />

        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <Translation>
                    {(t) => (
                      <h2 className="title theme-gradient">
                        {t(`contact.hubungi_kami`)}
                      </h2>
                    )}
                  </Translation>
                  <Translation>
                    {(t) => <p>{t(`contact.detail_hubungi_kami`)}</p>}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiPhone />
                  </div>
                  <div className="inner">
                    <Translation>
                      {(t) => <h4 className="title">{t(`contact.telepon`)}</h4>}
                    </Translation>
                    <p>
                      <a href="https://wa.me/+6281313133081">
                        +62 81 313133081{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    {/* <h4 className="title">Email Kami</h4> */}
                    <Translation>
                      {(t) => <h4 className="title">{t(`contact.email`)}</h4>}
                    </Translation>
                    <p>
                      <a href="mailto:pemasaran@ptbap.net">
                        pemasaran@ptbap.net
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50"></div>
              {/* End Single Address  */}

              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <Translation>
                      {(t) => (
                        <h4 className="title">
                          {t(`contact.kantor_pusat_sby`)}
                        </h4>
                      )}
                    </Translation>
                    <p>
                      Jl. Gayungsari Barat XI, Blok GD No. 13
                      <br />
                      Surabaya
                    </p>
                    <p>
                      <a href="tel:+62 31 8284343">+62 31 8284343</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <Translation>
                      {(t) => (
                        <h4 className="title">
                          {t(`contact.kantor_pusat_jkt`)}
                        </h4>
                      )}
                    </Translation>
                    <p>
                      Jl. Bambu Kuning Utara, No. 212 RT. 04 RW. 02
                      <br />
                      Kel. Bambu Apus Kec. Cipayung
                      <br />
                      Jakarta Timur
                    </p>
                    <p>
                      <a href="tel:+62 21 29835353">+62 21 29835353</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        <div className="rn-blog-area pt--120 pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <Translation>
                    {(t) => (
                      <h2 className="title">
                        {t(`corporate_bussiness.galeri_kerjasama`)}
                      </h2>
                    )}
                  </Translation>
                  <Translation>
                    {(t) => (
                      <p> {t(`corporate_bussiness.dokumentasi_kerjasama`)}</p>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostGalery.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      {/* <a onClick={e => e.preventDefault()} href="/blog-details"> */}
                      <img
                        className="w-100"
                        src={`/assets/images/kerjasama/${value.images}`}
                        alt="Blog Images"
                      />
                      {/* </a> */}
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4
                        className="title"
                        style={{ color: "white", marginBottom: "60px" }}
                      >
                        {value.title}
                      </h4>

                      {/* <h4 className="title"><a href="/blog-details">{value.title}</a></h4> */}
                      {/* <div className="blog-btn">
                                                <a onClick={e => e.preventDefault()} className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="rn-blog-area pt--120 pb--80 bg_color--1">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center">
                  <Translation>
                    {(t) => (
                      <h2 className="title">
                        {t(`contact.dokumentasi_pelatihan`)}
                      </h2>
                    )}
                  </Translation>
                  <Translation>
                    {(t) => <p> {t(`contact.detail_dokumentasi_pelatihan`)}</p>}
                  </Translation>
                </div>
              </div>
            </div>
            <div className="row mt--60">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      {/* <a onClick={e => e.preventDefault()} href="/blog-details"> */}
                      <img
                        className="w-100"
                        src={`/assets/images/kegiatan/${value.images}.jpg`}
                        alt="Blog Images"
                      />
                      {/* </a> */}
                    </div>
                    <div className="content">
                      <p className="blogtype">{value.category}</p>
                      <h4
                        className="title"
                        style={{ color: "white", marginBottom: "60px" }}
                      >
                        {value.title}
                      </h4>

                      {/* <h4 className="title"><a href="/blog-details">{value.title}</a></h4> */}
                      {/* <div className="blog-btn">
                                                <a onClick={e => e.preventDefault()} className="rn-btn text-white" href="/blog-details">Read More</a>
                                            </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Start Contact Page Area  */}
        {/* <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div> */}
        {/* End Contact Page Area  */}

        {/* Start Contact Map  */}
        {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default Contact;
