import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { Button, Dropdown,DropdownButton,DropdownItem } from 'react-bootstrap';
import i18next from 'i18next';
import { Translation } from 'react-i18next';

const EN = 'EN';
const IDN = 'IDN';


class HeaderFive extends Component{
    
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
        var x = localStorage.getItem("i18nextLng");
        if(x == null){
            this.state={bahasa: "EN"};
        }else{
            this.state={bahasa: x};
        }
        
        console.log(x)
       
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render(){
        
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { logo, color , headerPosition } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="BAP LOGO" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="BAP LOGO" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="BAP LOGO" />;
        }else if(logo === 'all-dark'){
            logoUrl = <img src="/assets/images/logo/bap.png" alt="BAP LOGO" />;
        } else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="BAP LOGO" />;
        }else{
            logoUrl = <img src="/assets/images/logo/invert-bap.png" alt="BAP LOGO" />;
        }
        
       
        return(
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-6">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu" style={{alignItems:'center'}}>
                                        <li><Link to="/"><Translation>{t => <p>{t('header.beranda_hdr')}</p>}</Translation></Link></li>
                                            <li><Link to="/about"><Translation>{t => <p>{t('header.tentang_kami_hdr')}</p>}</Translation></Link></li>
                                            {/* <li className="has-droupdown"><Link to="#" >Layanan</Link>
                                                <ul className="submenu">
                                                    <li><Link to="/portfolio">Jasa Keamanan</Link></li>
                                                    <li><Link to="/team">Jasa Pelatihan Satpam</Link></li>
                                                    <li><a href="https://it.bapguard.com/" target="_blank">Jasa IT</a></li>
                                                    <li><Link to="/video-popup">Jasa Kebersihan</Link></li>
                                                    <li><Link to="/progressbar">Jasa Transportasi</Link></li>
                                                </ul>
                                            </li> */}
                                            <li><Link to="/portfolio-home"><Translation>{t => <p>{t('header.klien_hdr')}</p>}</Translation></Link></li>
                                            <li><Link to="/contact" ><Translation>{t => <p >{t('header.hubungi_kami_hdr')}</p>}</Translation></Link></li>
                                            <li ><Button style={{ marginRight:20,borderColor:"red",width:100,padding:0,height:32,paddingTop:2,borderWidth:2}} variant="outline-light" href="https://backoffice.bapguard.com" target='_blank'><b>MY BAP</b></Button></li>
                                            {/* <li><Link to="https://backoffice.bapguard.com" >My BAP</Link></li> */}
                                           
                                        </ul>
                                    </nav>

                                    <DropdownButton
                                        variant="outline-primary"
                                        title={this.state.bahasa}
                                        id="input-group-dropdown-1"
                                        // className="buttonlang" 
                                        
                                        size="sm"
                                        >
                                        <Dropdown.Item 
                                         onClick={() => {
                                            i18next.changeLanguage(EN)
                                            this.setState({
                                                bahasa: "EN"
                                            })
                                            }}>English</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            i18next.changeLanguage(IDN) 
                                            this.setState({
                                                bahasa: "IDN"
                                            })}}>Indonesia</Dropdown.Item>
                                    </DropdownButton>
                                    

                                    {/* <div className="buttonlang">
                                        <a className="rn-btn" href="https://backoffice.bapguard.com" target='_blank'>
                                            <span>My BAP</span>
                                        </a>
                                    </div> */}

                                    
                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderFive;