import React from "react";

let data = [
    {
        images: '01',
    },
    {
        images: '02',
    },
    {
        images: '03',
    },
    {
        images: '04',
    },
    {
        images: '05',
    },
];

export default data;